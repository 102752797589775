<section class="relative">
    <form [formGroup]="signinForm">
        <div class="container px-20 lg:items-center pt-30 lg:pt-60 pb-110 max-w-700 m-auto">
            <h2 class="max-w-700 mb-25">Inscrivez votre entreprise</h2>
    
            <div class="text-left">
                <h4 class="mb-15 mt-10">Votre compte</h4>

                <ul>
                    <li class="mb-15">
                        <input class=" px-20 py-5 rounded-25" type="text" name="name" formControlName="name" placeholder="Nom d'utilisateur">
                    </li>
                    <li class="mb-15">
                        <input class=" px-20 py-5 rounded-25" type="text" name="email" formControlName="email" placeholder="Email">
                    </li>
                    <li class="mb-15">
                        <input class=" px-20 py-5 rounded-25" type="text" name="password" formControlName="password" placeholder="Mot de passe">
                    </li>
                </ul>
            </div>

            <div>
                <h4 class="mb-15 mt-10">Il s'agit de</h4>
                <select class="px-20 py-5 rounded-25 bg-white" name="role" formControlName="role" id="">
                    <option value="companies_manager">Une entreprise</option>
                    <option value="organizations_manager">Un organisme</option>
                </select>
            </div>

            <div>
                <h4 class="mb-15 mt-10">Votre entreprise</h4>

                <p>Vous pourrez changer ces informations une fois votre compte créé.</p>

                <ul>
                    <li class="mb-15">
                        <input class=" px-20 py-5 rounded-25" type="text" name="relation_name" formControlName="relation_name" placeholder="Nom">
                    </li>
                    <li class="mb-15">
                        <input class=" px-20 py-5 rounded-25" type="text" name="siret" formControlName="siret" placeholder="N° Siret">
                    </li>
                    <li class="mb-15">
                        <input class=" px-20 py-5 rounded-25" type="text" name="description" formControlName="description" placeholder="Description">
                    </li>
                </ul>
            </div>

            <button
                uiButton
                (click)="submit()"
                class="mt-30 font-medium bg-primary text-white"
                [ngClass]="{'!bg-primary-light text-black': signinForm.invalid}"
                [disabled]="signinForm.invalid"
                >
                Créer mon compte
            </button>
        </div>

    </form>
</section>
